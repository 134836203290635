<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <app-logo />
        </b-link>
        <b-alert
          v-if="authResult.error"
          variant="danger"
          show
        >
          <div class="alert-body text-center">
            {{ authResult.error }}
          </div>
        </b-alert>
        <b-card-title class="text-center mb-1">
          Sizin için buradayız! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          <div class="text-center">
            Uygulama anahtarı ile girişiniz kontrol ediliyor. Lütfen bekleyiniz...
          </div>
          <div class="text-center mt-3">
            <b-spinner />
          </div>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard, BCardTitle, BCardText, BLink, BSpinner, BAlert,
} from 'bootstrap-vue'
import AppLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AppLoginVue',
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BSpinner,
    BAlert,
    AppLogo,
  },
  computed: {
    authResult() {
      return this.$store.getters['tokenAuth/authResult']
    },
  },
  watch: {
    authResult(val) {
      if (val.userData) {
        this.setAuth(val)
      }
    },
  },
  created() {
    this.appLogin()
  },
  methods: {
    appLogin() {
      localStorage.removeItem('downloadToken')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userData')
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData) {
        this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Merhaba ${userData.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Oturum açma işleminiz başarılı!',
              },
            })
          })
      }
      this.$store.dispatch('tokenAuth/appLogin', this.$route.params.appToken)
    },
    setAuth(data) {
      const { userData } = data
      useJwt.setToken(data.accessToken)
      useJwt.setRefreshToken(data.refreshToken)
      useJwt.setDownloadToken(data.downloadToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$ability.update(userData.ability)
      this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Merhaba ${userData.username}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Oturum açma işleminiz başarılı!',
            },
          })
        })
      // this.$router.push('/client/supports/add')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
